/*
*   index.css defines css rules for broadly used elements
*/

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #0f0f0f;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

html,
#root,
body {
    min-height: 100%;
    min-width: 100%;
}

#root {
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    /* font-family: 'Playfair Display', serif; */
    font-family: 'Open Sans';
    text-align: center;
    word-wrap: break-word;
    transition: 0.5s;
}

input,
textarea {
    font-family: 'Open Sans';
}

div {
    /* font-family: 'Playfair Display', serif; */
    font-family: 'Open Sans';
    text-align: center;
    word-wrap: break-word;
    transition: 0.5s background-color, 0.5s color;
    overflow: visible;
}

li {
    text-align: left;
    list-style-image: url("../assets/svg/bullet.svg");
}

/* fancy link definitions */

a,
a:visited {
    position: relative;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    transition: 0.5s;
}

a::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #ac3b61;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
    transform-origin: 0% 100%;
}

a:hover::before {
    visibility: visible;
    transform: scaleX(1);
}